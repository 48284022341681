/**
 * @description Converts an array to a deeply nested object
 *
 * e.g.,
 *
 * const res = arrayToObject([1,2,3,4,5]);
 *
 * output: { 1: { 2: { 3: { 4: { 5: null } } } }
 *
 */
export function arrayToObject(arr: string[]) {
  return arr.reduceRight((prev, current) => {
    if (isEmpty(prev)) {
      return { [current]: null };
    }

    return { [current]: prev };
  }, {});
}

const isEmpty = (obj: { [key: string]: unknown }) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
