import { PropsWithChildren, useContext } from "react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import "./FileContextMenu.css";
import { RESTRICTED_FILES } from "../../constants/constants";
import { ModalContext } from "../../providers/ModalContext";
import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import { useTracking } from "../../tracking";
import { SandpackUIContext } from "../../providers/SandpackUIContext";

interface Props extends PropsWithChildren {
  isDir?: boolean;
  onRenameStart: () => void;
  nodePath: string;
}

export const FileContextMenu = (props: Props) => {
  const { children, isDir = false, onRenameStart, nodePath } = props;
  const label = isDir ? "Folder" : "File";
  const isRestrictedFile = RESTRICTED_FILES.includes(nodePath);
  const { showModal } = useContext(ModalContext);
  const values = useContext(SandpackUIContext);
  const { deleteFile } = useSandpackFiles();
  const { trackEvent } = useTracking();
  const nodeType = isDir ? "directory" : "file";

  const handleConfirmDelete = () => {
    if (isDir) {
      return deleteFile(`${nodePath}/`);
    }

    return deleteFile(nodePath);
  };

  if (values.readOnly) {
    return children;
  }

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className="context-menu-content">
          <ContextMenu.Item
            className="context-menu-item"
            data-disabled={isRestrictedFile}
            onClick={() => {
              trackEvent(`playground.delete-${nodeType}.start`, {
                path: nodePath,
              });

              return showModal({
                id: "delete-file-modal",
                props: {
                  actionName: `delete-${nodeType}`,
                  isDir,
                  fileName: nodePath,
                  handleConfirmDelete,
                },
              });
            }}
          >
            Delete {label}
            <div className="context-menu-item__right-slot">⌘+Backspace</div>
          </ContextMenu.Item>
          <ContextMenu.Item
            data-disabled={isRestrictedFile}
            className="context-menu-item"
            onClick={() => {
              onRenameStart();
            }}
          >
            Rename {label}
            <div className="context-menu-item__right-slot">Enter</div>
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};
