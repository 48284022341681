import { SandpackFiles } from "@codesandbox/sandpack-react";
import { getFileContents } from "./getFileContents";

export const isInPackageJson = (packageJson: string, packageName: string) => {
  return packageJson.includes(`"${packageName}":`);
};

export const isReactTypescript = (files: SandpackFiles) => {
  return files["src/index.tsx"];
};

export const isVueTypeScript = (files: SandpackFiles) => {
  return files["src/main.ts"];
};

export const getEntryFile = (files: SandpackFiles) => {
  const packageJson = getFileContents(files["package.json"] ?? "");

  if (isReactTypescript(files)) {
    return "src/index.tsx";
  }

  if (isInPackageJson(packageJson, "react")) {
    return "src/index.jsx";
  }

  if (isVueTypeScript(files)) {
    return "src/main.ts";
  }

  if (isInPackageJson(packageJson, "vue")) {
    return "src/main.js";
  }

  return "index.html";
};
