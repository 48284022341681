import { useEffect, useState } from "react";

interface Options {
  dragEventTargetRef: React.MutableRefObject<HTMLElement | null>;
}

export function useResizablePanels(options: Options) {
  const { dragEventTargetRef } = options;

  const [horizontalSize, setHorizontalSize] = useState(50);

  const onDragMove = (event: MouseEvent): void => {
    if (!dragEventTargetRef.current) return;

    const container = dragEventTargetRef.current.parentElement as
      | HTMLDivElement
      | undefined;

    if (!container) return;

    const { left, width } = container.getBoundingClientRect();
    const offset = ((event.clientX - left) / width) * 100;

    const boundaries = Math.min(Math.max(offset, 25), 75);

    setHorizontalSize(boundaries);

    container.querySelectorAll(`.sp-stack`).forEach((item) => {
      (item as HTMLDivElement).style.pointerEvents = "none";
    });
  };

  const stopDragging = (): void => {
    const container = dragEventTargetRef.current?.parentElement as
      | HTMLDivElement
      | undefined;

    if (!container) return;

    container.querySelectorAll(`.sp-stack`).forEach((item) => {
      (item as HTMLDivElement).style.pointerEvents = "";
    });

    dragEventTargetRef.current = null;
  };

  useEffect(() => {
    document.body.addEventListener("mousemove", onDragMove);
    document.body.addEventListener("mouseup", stopDragging);

    return (): void => {
      document.body.removeEventListener("mousemove", onDragMove);
      document.body.removeEventListener("mouseup", stopDragging);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    horizontalSize,
  };
}
