import { PropsWithChildren, createContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import { saveChanges } from "../api/saveChanges";
import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { Environment } from "../config/endpoints";
import { useTracking } from "../tracking";

interface SaveArgs {
  sessionId: string;
  files: SandpackBundlerFiles;
  environment: Environment;
}

export const SaveChangesContext = createContext<{
  save: (args: SaveArgs) => Promise<void>;
  isSaving: boolean;
  touchedFiles: Set<string>;
  isDirty: boolean;
  saveCount: number;
  onFileChange: (fileName: string | string[]) => void;
}>({
  save: () => Promise.resolve(),
  isSaving: false,
  touchedFiles: new Set(),
  isDirty: false,
  saveCount: 0,
  onFileChange: () => null,
});

export function SaveChangesProvider(props: PropsWithChildren) {
  const [touchedFiles, setTouchedFiles] = useState<Set<string>>(
    () => new Set(),
  );

  const prevFilesRef = useRef<Set<string>>(new Set());
  const { trackEvent } = useTracking();

  const [saveCount, setSaveCount] = useState(0);
  const isDirty = touchedFiles.size > 0;

  const { status, mutateAsync: save } = useMutation<void, unknown, SaveArgs>({
    mutationFn: (args) => saveChanges(args),
    retry: false,
    onSuccess() {
      trackEvent("playground.save.success");
      setSaveCount(saveCount + 1);
    },
    onMutate() {
      trackEvent("playground.save.start");
      prevFilesRef.current = new Set(...touchedFiles);
      setTouchedFiles(new Set());
    },
    onError() {
      trackEvent("playground.save.error");
      setTouchedFiles(new Set(...prevFilesRef.current, ...touchedFiles));
    },
    onSettled() {
      prevFilesRef.current = new Set();
    },
  });

  const onFileChange = (fileName: string | string[]) => {
    if (Array.isArray(fileName)) {
      setTouchedFiles((prev) => new Set([...prev, ...fileName]));
      return;
    }
    setTouchedFiles((prev) => new Set([...prev, fileName]));
  };

  const isSaving = status === "loading";

  return (
    <SaveChangesContext.Provider
      value={{
        save,
        onFileChange,
        isSaving,
        touchedFiles,
        isDirty,
        saveCount,
      }}
    >
      {props.children}
    </SaveChangesContext.Provider>
  );
}
