import { useContext } from "react";
import { GenerationSessionContext } from "../providers/GenerationSession";
import { SandpackUIContext } from "../providers/SandpackUIContext";
import * as Sentry from "@sentry/react";

type EventParams = {
  [key: string]: string | number | boolean;
};

const isProd = () => import.meta.env.PROD;

type TrackingEvent = {
  eventAction: string;
  userID?: string;
  userEmail?: string;
  time: number;
  params: EventParams;
};

const collect = (event: TrackingEvent[]) => {
  if (!isProd()) return Promise.resolve();

  return fetch("https://l.animaapp.com/analytics/collect", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-Id": "playground",
    },
    body: JSON.stringify(event),
  }).catch((err) => {
    Sentry.captureMessage("Failed to send tracking event", err);
  });
};

export const useTracking = () => {
  const { userId, teamId, userEmail } = useContext(GenerationSessionContext);
  const { debugTrackingEvents } = useContext(SandpackUIContext);

  const trackEvent = (
    action: string,
    eventParams: EventParams = {},
    sessionId?: string,
  ) => {
    const event: TrackingEvent = {
      eventAction: action,
      userID: userId,
      userEmail,
      params: {
        platform: "playground",
        origin: "playground",
        ...(teamId ? { teamId: teamId } : {}),
        ...eventParams,
        ...(sessionId ? { session_id: sessionId } : {}),
      },
      time: new Date().getTime(),
    };

    if (debugTrackingEvents) {
      console.info("Event", event);
    }

    return collect([event]);
  };

  return {
    trackEvent,
  };
};
