// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, timeout = 300) {
  let timer: NodeJS.Timeout;
  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      //@ts-expect-error this is a function
      func.apply(this, args);
    }, timeout);
  };
}
