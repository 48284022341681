import type { SandpackBundlerFiles } from "@codesandbox/sandpack-client";

const isObjectEmpty = (obj: Record<string, unknown>, prop: string) => {
  return Object.keys(obj?.[prop] || {})?.length === 0;
};

export function deepMerge(
  files: SandpackBundlerFiles,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj1: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj2: any,
  path: string,
) {
  // Create a new object by copying the properties of obj1
  const result = { ...obj1 };

  for (const prop in obj2) {
    if (!result[prop]) {
      result[prop] = {};
    }

    if (typeof obj2[prop] === "object") {
      if (isObjectEmpty(obj1, prop) && isObjectEmpty(obj2, prop)) {
        result[prop] = files[`${prop}`]?.code;
        continue;
      }

      // Check if either of the objects being merged are empty and return the non-empty object instead of calling deepMerge()
      if (isObjectEmpty(obj1, prop)) {
        result[prop] = Object.entries(obj2[prop]).reduce(
          (acc, [key, value]) => {
            if (value !== null) {
              acc[key] = deepMerge(files, result[prop], value, path);
            } else {
              acc[key] = files[`${prop}/${key}`]?.code;
            }
            return acc;
          },
          {} as Record<string, unknown>,
        );
        continue;
      }

      if (isObjectEmpty(obj2, prop)) {
        result[prop] = obj1[prop];
        continue;
      }

      result[prop] = deepMerge(files, result[prop], obj2[prop], path);
      continue;
    }

    result[prop] = files[`${path}`]?.code;
  }

  return result;
}
