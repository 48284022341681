import { Files, OverrideFiles, directoryFileMap } from "./directoryFileMap";
import { toHierarchicalArray } from "./toHierarchicalArray";

export const createTreeFromFiles = (
  files: Files,
  overrides: OverrideFiles = {},
) => {
  return toHierarchicalArray(directoryFileMap(files, overrides)).filter(
    ({ text }) => Boolean(text),
  );
};
