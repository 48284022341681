import defaultFileIconPath from "../icons/file-icon.svg";
import cssPath from "../icons/languages/css.svg";
import gitPath from "../icons/languages/git.svg";
import htmlPath from "../icons/languages/html.svg";
import jsPath from "../icons/languages/js.svg";
import jsonPath from "../icons/languages/json.svg";
import jsxPath from "../icons/languages/jsx.svg";
import mdPath from "../icons/languages/markdown.svg";
import scssPath from "../icons/languages/scss.svg";
import svgPath from "../icons/languages/svg.svg";
import tsPath from "../icons/languages/ts.svg";
import tsxPath from "../icons/languages/tsx.svg";
import vuePath from "../icons/languages/vue.svg";

export function getIconPathForFileType(file: string) {
  if (file.endsWith(".css")) return cssPath;
  if (file.endsWith(".gitignore")) return gitPath;
  if (file.endsWith(".html")) return htmlPath;
  if (file.endsWith(".json") || (file.endsWith("rc") && file.startsWith(".")))
    return jsonPath;
  if (file.endsWith(".js")) return jsPath;
  if (file.endsWith(".jsx")) return jsxPath;
  if (file.endsWith(".md")) return mdPath;
  if (file.endsWith(".scss") || file.endsWith(".sass")) return scssPath;
  if (file.endsWith(".svg")) return svgPath;
  if (file.endsWith(".ts")) return tsPath;
  if (file.endsWith(".tsx")) return tsxPath;
  if (file.endsWith(".vue")) return vuePath;

  return defaultFileIconPath;
}
