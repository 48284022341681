import { useContext } from "react";
import { CreateNewNode } from "../CreateNewNode/CreateNewNode";
import "./FileTreeExplorerActions.css";
import { SandpackUIContext } from "../../providers/SandpackUIContext";

interface Props {
  activeFile: string;
}

export function FileTreeExplorerActions(props: Props) {
  const values = useContext(SandpackUIContext);
  const { activeFile } = props;
  return (
    <div className="file-tree-explorer-actions">
      <h2>Files</h2>
      {!values.readOnly ? (
        <div className="file-tree-explorer-actions__new-item">
          <CreateNewNode activeFile={activeFile} />
          <CreateNewNode dir activeFile={activeFile} />
        </div>
      ) : null}
    </div>
  );
}
