import { useContext } from "react";
import { SaveChangesContext } from "../../providers/SaveChanges";
import saveIndicatorImg from "../../icons/save-indicator.svg";
import "./TopBarSavingState.css";

export function TopBarSavingState() {
  const { isDirty, isSaving } = useContext(SaveChangesContext);

  if (isSaving || isDirty) {
    const label = isSaving ? "Saving..." : "Unsaved Changes";

    return (
      <div className="saving-state" aria-label={label}>
        <img src={saveIndicatorImg} alt="" />
      </div>
    );
  }

  return null;
}
