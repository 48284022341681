import { useContext } from "react";
import { SaveChangesContext } from "../../providers/SaveChanges";
import saveIcon from "../../icons/save.svg";
import { TopBarSavingState } from "../TopBarSavingState/TopBarSavingState";
import "./SaveButton.css";

const tooltipMessages: Record<string, string> = {
  saving: "Saving...",
  unsavedChanges: "Save (CMD + S)",
  saved: "Saved",
};

export function SaveButton() {
  const { isDirty, isSaving } = useContext(SaveChangesContext);

  const tooltipLabelKeys: Record<string, boolean> = {
    saving: isSaving,
    unsavedChanges: isDirty,
    saved: !isSaving && !isDirty,
  };

  const tooltipLabel = Object.keys(tooltipLabelKeys).find(
    (key) => tooltipLabelKeys[key],
  );
  const label = tooltipLabel ? tooltipMessages[tooltipLabel] : "";

  return (
    <div
      className="save-button__wrapper"
      aria-label={label}
      data-balloon-pos="down"
    >
      <button
        data-size="small"
        data-variant="link"
        disabled={!isDirty || isSaving}
        aria-label="Save Project"
        onClick={() => {
          document.dispatchEvent(new CustomEvent("playground:save"));
        }}
      >
        <img src={saveIcon} alt="save button" />
      </button>

      <div className="save-button__state">
        <TopBarSavingState />
      </div>
    </div>
  );
}
