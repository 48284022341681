import { useContext } from "react";
import { DownloadProjectContext } from "../../providers/DownloadProject";
import { useParams } from "react-router-dom";
import downloadIcon from "../../icons/download.svg";

export function DownloadButton() {
  const { isDownloading, isAllowed } = useContext(DownloadProjectContext);
  const { id } = useParams<{ id: string }>();

  const tooltipLabel = isAllowed
    ? "Download Project"
    : "Upgrade to Anima Pro to download code";

  return (
    <div aria-label={tooltipLabel} data-balloon-pos="down-right">
      <button
        data-size="small"
        data-variant="link"
        aria-label="Download Project"
        data-balloon-pos="down-right"
        disabled={isDownloading || !id || !isAllowed}
        onClick={() => {
          document.dispatchEvent(new CustomEvent("playground:download"));
        }}
      >
        <img alt="" src={downloadIcon} />
      </button>
    </div>
  );
}
