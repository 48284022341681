import * as Dialog from "@radix-ui/react-dialog";
import { DialogBase } from "../DialogBase/DialogBase";
import { useTracking } from "../../tracking";

interface Props {
  fileName: string;
  handleConfirmDelete: () => void;
  handleClose: () => void;
  open: boolean;
  isDir: boolean;
  actionName?: string;
}

export function DeleteFileModal(props: Props) {
  const { fileName, handleConfirmDelete, open, handleClose, actionName } =
    props;

  const title = `Are you sure you want to permanently delete '${fileName}'`;

  return (
    <DialogBase
      open={open}
      title={title}
      subtitle="You cannot undo this action."
      handleClose={handleClose}
      actionName={actionName}
      actions={
        <Actions
          fileName={fileName}
          handleClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          isDir={props.isDir}
        />
      }
    />
  );
}

interface ActionProps {
  handleConfirmDelete: () => void;
  handleClose: () => void;
  fileName: string;
  isDir: boolean;
}

function Actions(props: ActionProps) {
  const { handleConfirmDelete, handleClose, fileName, isDir } = props;
  const { trackEvent } = useTracking();
  const nodeType = isDir ? "directory" : "file";

  return (
    <>
      <Dialog.Close asChild>
        <button
          onClick={() => {
            trackEvent(`playground.delete-${nodeType}.cancel`, {
              path: fileName,
            });
            return handleClose();
          }}
          data-testid="delete-file-modal-cancel-button"
        >
          Cancel
        </button>
      </Dialog.Close>
      <Dialog.Close asChild>
        <button
          data-testid="delete-file-modal-delete-button"
          data-variant="danger"
          onClick={() => {
            trackEvent(`playground.delete-${nodeType}.confirm`, {
              path: fileName,
            });
            handleConfirmDelete();
            handleClose();
          }}
        >
          Delete
        </button>
      </Dialog.Close>
    </>
  );
}
