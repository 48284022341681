import "./FeedbackButton.css";

export function FeedbackButton() {
  return (
    <a
      className="button feedback-button"
      data-variant="ghost"
      data-size="small"
      rel="noreferrer noopener"
      target="_blank"
      href="https://form.asana.com/?k=MhmNO3rLLNGW5hD2FY36Dw&d=1152665201300829"
    >
      Feedback
    </a>
  );
}
