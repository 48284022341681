import { ToastMessage } from "./Toast";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export const toastMessages: Record<string, ToastMessage> = {
  mui_no_theme: {
    title: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InfoCircledIcon color="var(--blue-11)" />
        <span style={{ marginLeft: 8, color: "var(--blue-11)" }}>
          MUI Theme Not Generated
        </span>
      </div>
    ),
    description:
      "We're using the default MUI theme in this preview to help ensure integration with your project's custom theme. Don't have a theme? adjust the settings in the plugin, and Anima will generate a custom MUI theme for you.",
  },
};
