const headMatcher = /<head[^<>]*>/;
const bodyMatcher = /<body[^<>]*>/;

export const insertIntoHead = (oldHtml: string, content: string) => {
  return insertIntoHTMLElement(oldHtml, content, headMatcher);
};

export const insertIntoBody = (oldHtml: string, content: string) => {
  return insertIntoHTMLElement(oldHtml, content, bodyMatcher);
};

export const insertIntoHTMLElement = (
  oldHtml: string,
  content: string,
  matcher: RegExp,
) => {
  const matchedHtml = oldHtml.match(matcher);

  if (!matchedHtml) {
    return oldHtml;
  }

  if (oldHtml.includes(content)) {
    return oldHtml;
  }

  const splitScript = oldHtml.split(matcher);
  const slicedEl = splitScript.slice(1);

  return [splitScript[0], content, slicedEl].join("");
};
