import { useSandpack } from "@codesandbox/sandpack-react";
import { useEffect, useRef } from "react";
import { useTracking } from "../tracking";
import { debounce } from "../utilities/utils";

export const useTrackBuildError = () => {
  const { sandpack, listen } = useSandpack();
  const { trackEvent } = useTracking();

  const editorState = useRef(sandpack.editorState);
  editorState.current = sandpack.editorState;
  // debounce the build error tracking
  const trackBuiltError = debounce(
    (args: Record<string, string>) =>
      trackEvent("playground.build-error", {
        editorState: editorState.current,
        ...args,
      }),
    100,
  );

  useEffect(() => {
    const stopListening = listen((msg) => {
      if (msg.type === "action" && msg.action === "show-error") {
        trackBuiltError({
          message: msg.message,
          file: msg.path,
        });
      }
    });

    return () => {
      stopListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
