import { useTracking } from "../../tracking";
import "./UpgradeButton.css";

export function UpgradeButton() {
  const { trackEvent } = useTracking();

  const onClick = () => {
    trackEvent("playground.upgrade");
  };

  return (
    <div
      aria-label={"Upgrade to Anima Pro to download code"}
      data-balloon-pos="down"
    >
      <a
        className="button upgrade-button"
        data-variant="link"
        data-size="small"
        rel="noreferrer noopener"
        target="_blank"
        href="https://projects.animaapp.com/pricing"
        onClick={onClick}
      >
        Upgrade
      </a>
    </div>
  );
}
