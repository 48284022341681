import { useSandpack } from "@codesandbox/sandpack-react";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SaveChangesContext } from "../providers/SaveChanges";
import { DevtoolsContext } from "../components/SandpackDevTools/SandpackDevTools";

export const useAddSaveListener = () => {
  const { isDirty, save, isSaving } = useContext(SaveChangesContext);
  const { environment } = useContext(DevtoolsContext);
  const { id } = useParams<{ id: string }>();
  const { sandpack } = useSandpack();
  const { files } = sandpack;

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty || isSaving) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, isSaving]);

  useEffect(() => {
    if (!id || isSaving) return;

    const handleSave = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "s") {
        e.preventDefault();
        if (!isDirty) return;
        save({
          sessionId: id,
          files,
          environment,
        });
      }
    };

    const handleCustomSave = () => {
      save({
        sessionId: id,
        files,
        environment,
      });
    };

    document.addEventListener("keydown", handleSave);
    document.addEventListener("playground:save", handleCustomSave);

    return () => {
      if (!id) return;
      document.removeEventListener("keydown", handleSave);
      document.removeEventListener("playground:save", handleCustomSave);
    };
  }, [isDirty, save, id, files, environment, isSaving]);

  return null;
};
