import { createContext, useState } from "react";

const defaultValues = {
  showNavigator: true,
  showRefreshButton: true,
  showTabs: false,
  closableTabs: false,
  resizablePanels: true,
  readOnly: false,
  isPreview: false,
  showFileExplorer: true,
  showCodeEditor: true,
  debugTrackingEvents: false,
  onChange: () => null,
  fetchSandpackCode: () => null,
  toggleFullScreen: () => null,
  getIsFullScreen: () => false,
};

export interface SandpackUIContextType {
  showNavigator: boolean;
  showRefreshButton: boolean;
  showTabs: boolean;
  closableTabs: boolean;
  resizablePanels: boolean;
  readOnly: boolean;
  isPreview: boolean;
  showFileExplorer: boolean;
  showCodeEditor: boolean;
  debugTrackingEvents: boolean;
  onChange: (key: keyof SandpackUIContextType, value: string | boolean) => void;
  fetchSandpackCode: (path: string) => null;
  toggleFullScreen: (shouldExpandPreview: boolean) => void;
  getIsFullScreen: () => boolean;
}

export const SandpackUIContext =
  createContext<SandpackUIContextType>(defaultValues);

export function SandpackUIProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [values, setValues] = useState(defaultValues);

  const onChange = (
    key: keyof SandpackUIContextType,
    checked: string | boolean,
  ) => {
    const updatedValues = {
      ...values,
      [key]: checked,
    };
    updatedValues.readOnly = updatedValues.readOnly || updatedValues.isPreview;
    setValues(updatedValues);
  };

  const getIsFullScreen = () => {
    const { showCodeEditor, showFileExplorer, showTabs } = values;
    return !showCodeEditor && !showFileExplorer && !showTabs;
  };

  const toggleFullScreen = (shouldExpandPreview: boolean) => {
    if (shouldExpandPreview) {
      return setValues({
        ...values,
        showCodeEditor: false,
        showFileExplorer: false,
      });
    }

    return setValues({
      ...values,
      showFileExplorer: true,
      showCodeEditor: true,
    });
  };

  return (
    <SandpackUIContext.Provider
      value={{
        ...values,
        onChange,
        toggleFullScreen,
        getIsFullScreen,
      }}
    >
      {children}
    </SandpackUIContext.Provider>
  );
}
