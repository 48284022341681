import { PropsWithChildren, createContext, useState } from "react";
import { DeleteFileModal } from "../components/DeleteFileModal/DeleteFileModal";

const MODALS = {
  "delete-file-modal": DeleteFileModal,
};

type ModalKey = keyof typeof MODALS;

interface ShowModalArgs {
  id: ModalKey;
  props?: Record<string, unknown>;
}

interface ModalContextType {
  showModal: (args: ShowModalArgs) => void;
  activeModal: ModalKey | null;
  closeModal: () => void;
}

const VoidComponent = () => null;

export const ModalContext = createContext<ModalContextType>(null!);

export function ModalProvider(props: PropsWithChildren) {
  const [activeModal, setActiveModal] = useState<ModalKey | null>(null);
  const [activeModalProps, setActiveModalProps] = useState<
    Record<string, unknown>
  >({});

  const showModal = (args: ShowModalArgs) => {
    setActiveModal(args.id);
    setActiveModalProps(args.props ?? {});
  };

  const closeModal = () => {
    setActiveModal(null);
    setActiveModalProps({});
  };

  const ActiveModal = activeModal ? MODALS[activeModal] : VoidComponent;

  return (
    <ModalContext.Provider
      value={{
        activeModal,
        closeModal,
        showModal,
      }}
    >
      {props.children}
      {/* @ts-expect-error refactor to use discriminated unions to handle propdrilling */}
      <ActiveModal open handleClose={closeModal} {...activeModalProps} />
    </ModalContext.Provider>
  );
}
