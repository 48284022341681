import React, { createContext, useState, ReactNode } from "react";
import * as Toast from "@radix-ui/react-toast";
import "./Toast.css";

export interface ToastContextType {
  showToast: (props: ToastMessage) => void;
}

export interface ToastMessage {
  title: string | ReactNode;
  description: string | ReactNode;
}

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined,
);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<ToastMessage & { open: boolean }>({
    open: false,
    title: "",
    description: "",
  });

  const showToast = ({ title, description }: ToastMessage) => {
    setToast({ open: true, title, description });
  };

  const closeToast = () => {
    setToast((prev) => ({ ...prev, open: false }));
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}

      <Toast.Provider duration={Infinity}>
        <Toast.Root
          className="toast-root"
          open={toast.open}
          onOpenChange={closeToast}
        >
          <Toast.Action asChild altText="Close" onClick={closeToast}>
            <div className="toast-content">
              <Toast.Title className="toast-title">{toast.title}</Toast.Title>
              <Toast.Description className="toast-description">
                {toast.description}
              </Toast.Description>
            </div>
          </Toast.Action>
        </Toast.Root>
        <Toast.Viewport className="toast-viewport" />
      </Toast.Provider>
    </ToastContext.Provider>
  );
};
