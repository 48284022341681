import { getIconPathForFileType } from "../../utilities/getIconPathForFileType";
import { SimpleNodeModel } from "../../utilities/getNodePath";
import "./FileNode.css";

interface Props {
  node: SimpleNodeModel;
  isActive: boolean;
}

export function FileNode(props: Props) {
  const { node, isActive } = props;
  const fileIconPath = getIconPathForFileType(node.text);

  return (
    <span className="file-node">
      <img
        src={fileIconPath}
        alt=""
        className={`file-node__icon-wrapper ${
          isActive ? "file-node__icon-wrapper--active" : ""
        }`}
      />
      <span
        className="file-node__text truncate"
        data-active={isActive ? "true" : "false"}
      >
        {node.text}
      </span>
    </span>
  );
}
