import {
  ChangeEvent,
  FormEvent,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./SandpackDevTools.css";
import { useNavigate } from "react-router-dom";
import { getIsEmbedded } from "../../utilities/getIsEmbedded";
import {
  SandpackUIContext,
  SandpackUIContextType,
} from "../../providers/SandpackUIContext";
import { Environment } from "../../config/endpoints";

const defaultValues = {
  environment: "prod" as Environment,
  setEnvironment: () => null,
};

interface DevtoolsContextType {
  environment: Environment;
  setEnvironment: (environment: Environment) => void;
}

type SettingKey = Exclude<keyof SandpackUIContextType, "onChange">;

export function SandpackDevTools() {
  const ENVIRONMENTS = ["prod", "canary", "local"];
  const [isOpen, setOpenState] = useState(false);
  const [endpointIndex, setEndpointIndex] = useState(0);
  const {
    closableTabs,
    readOnly,
    resizablePanels,
    showNavigator,
    showRefreshButton,
    showTabs,
    showFileExplorer,
    showCodeEditor,
    debugTrackingEvents,
    onChange,
  } = useContext(SandpackUIContext);

  const { environment, setEnvironment } = useContext(DevtoolsContext);
  const navigate = useNavigate();
  const isEmbedded = getIsEmbedded();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const isClickInside = containerRef.current?.contains(
        e.target as HTMLDivElement,
      );

      if (!isClickInside) setOpenState(false);
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  if (import.meta.env.PROD) return null;

  const handleChange = (key: SettingKey) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      return onChange(key, e.target.checked);
    };
  };

  const toggleEndpoint = () => {
    const endpointValue = (endpointIndex + 1) % 3;
    setEnvironment(ENVIRONMENTS[endpointValue] as Environment);
    return setEndpointIndex(endpointValue);
  };

  const updateSandpackId = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const sandpackId = formData.get("sandpack-id") as string;
    navigate(`/${sandpackId}`);
  };

  return (
    <div
      className={`dev-tools ${isEmbedded ? "dev-tools--embedded" : ""}`}
      ref={containerRef}
    >
      <div className="dev-tools__button-wrapper">
        <button onClick={() => setOpenState(!isOpen)}>
          {isOpen ? "Close DevTools" : "Open DevTools"}
        </button>
      </div>
      {isOpen && (
        <div className="dev-tools__content">
          <div>
            <input
              defaultChecked={resizablePanels}
              type="checkbox"
              id="resize"
              onChange={handleChange("resizablePanels")}
            />
            <label htmlFor="resize">Resize Window</label>
          </div>
          <div>
            <input
              defaultChecked={readOnly}
              type="checkbox"
              id="readonly"
              onChange={handleChange("readOnly")}
            />
            <label htmlFor="readonly">Readonly Mode</label>
          </div>
          <div>
            <input
              defaultChecked={showTabs}
              type="checkbox"
              id="show-tabs"
              onChange={handleChange("showTabs")}
            />
            <label htmlFor="show-tabs">Show Tabs</label>
          </div>
          <div>
            <input
              defaultChecked={closableTabs}
              type="checkbox"
              id="closable-tabs"
              onChange={handleChange("closableTabs")}
            />
            <label htmlFor="closable-tabs">Closable Tabs</label>
          </div>
          <div>
            <input
              defaultChecked={showFileExplorer}
              type="checkbox"
              id="file-explorer"
              onChange={handleChange("showFileExplorer")}
            />
            <label htmlFor="file-explorer">Show File Explorer</label>
          </div>
          <div>
            <input
              defaultChecked={showNavigator}
              type="checkbox"
              id="navigator"
              onChange={handleChange("showNavigator")}
            />
            <label htmlFor="navigator">Show Navigator</label>
          </div>
          <div>
            <input
              defaultChecked={showRefreshButton}
              type="checkbox"
              id="refresh"
              onChange={handleChange("showRefreshButton")}
            />
            <label htmlFor="refresh">Show Refresh Button</label>
          </div>
          <div>
            <input
              defaultChecked={showCodeEditor}
              type="checkbox"
              id="code-editor"
              onChange={handleChange("showCodeEditor")}
            />
            <label htmlFor="code-editor">Show Code Editor</label>
          </div>
          <div>
            <input
              defaultChecked={debugTrackingEvents}
              type="checkbox"
              id="debug-events"
              onChange={handleChange("debugTrackingEvents")}
            />
            <label htmlFor="debug-events">Debug Events</label>
          </div>
          <div className="devtools__switch">
            <p>Endpoint: {environment}</p>
            <button onClick={toggleEndpoint}>Switch</button>
          </div>
          <form
            className="column devtools__id-wrapper"
            onSubmit={updateSandpackId}
          >
            <label htmlFor="sandpack-id">Sandpack ID:</label>
            <div className="row full-width">
              <input name="sandpack-id" id="sandpack-id" />
              <button>Get</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export const DevtoolsContext =
  createContext<DevtoolsContextType>(defaultValues);

export function DevtoolsProvider({ children }: { children: React.ReactNode }) {
  const [environment, setEnvironment] = useState(defaultValues.environment);

  return (
    <DevtoolsContext.Provider
      value={{
        environment,
        setEnvironment,
      }}
    >
      {children}
    </DevtoolsContext.Provider>
  );
}
