import { SandpackFiles } from "@codesandbox/sandpack-react";
import { getFileContents } from "./getFileContents";
import { insertIntoBody } from "./insertIntoHead";
import { getEntryFile } from "./fileHelpers";

// since we're pulling tailwind from the CDN, it won't read the config from the filesystem,
// instead we need to import it ourselves after making a slight adjustment to the file.

export const adjustTailwindConfigForCDN = (files: SandpackFiles) => {
  const tailwindConfig = files["tailwind.config.js"];
  if (!tailwindConfig) return;

  const tailwindConfigContents = getFileContents(tailwindConfig);

  const modifiedTailwindConfigContents = tailwindConfigContents.replace(
    "module.exports = {",
    "tailwind.config = {",
  );

  if (typeof files["tailwind.config.js"] === "string") {
    files["tailwind.config.js"] = modifiedTailwindConfigContents;
  } else {
    files["tailwind.config.js"].code = modifiedTailwindConfigContents;
  }
};

export const importTailwindFiles = (files: SandpackFiles) => {
  adjustTailwindConfigForCDN(files);

  const requiredFiles = ["tailwind.css", "tailwind.config.js"];
  const entryFileKey = getEntryFile(files);

  if (entryFileKey !== "index.html") {
    const imports = requiredFiles.reduce((acc, file) => {
      if (file in files) {
        return acc + `import "../${file}";\n`;
      }
      return acc;
    }, "");
    const entryFile = getFileContents(files[entryFileKey]);
    const updatedEntryFile = `${imports}${entryFile}`;
    files[entryFileKey] = updatedEntryFile;
    return;
  }

  const indexHtmlFile = getFileContents(files["index.html"]);
  const updatedContent = insertIntoBody(
    indexHtmlFile,
    `\n\t<script type="module" src="./tailwind.config.js"></script>`,
  );

  if (typeof files["index.html"] === "string") {
    files["index.html"] = updatedContent;
  } else {
    files["index.html"].code = updatedContent;
  }
};
