import { DndProvider } from "react-dnd";
import {
  MultiBackend,
  getBackendOptions,
  Tree,
  DropOptions,
} from "@minoru/react-dnd-treeview";
import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import "./FileTreeExplorer.css";
import { useSandpack } from "@codesandbox/sandpack-react";
import { FileTreeExplorerActions } from "../FileTreeExplorerActions/FileTreeExplorerActions";
import { AddFileForm } from "../AddFileForm/AddFileForm";
import { OpenFolderButton } from "../OpenFolderButton/OpenFolderButton";
import { OpenFileButton } from "../OpenFileButton/OpenFileButton";
import { SimpleNodeModel } from "../../utilities/getNodePath";
import { buildPath } from "../../utilities/buildPath";
import { Item } from "../../utilities/toHierarchicalArray";
import { RESTRICTED_FILES } from "../../constants/constants";
import { useTracking } from "../../tracking";
import { SandpackUIContext } from "../../providers/SandpackUIContext";
import { useContext } from "react";

export const FileTreeExplorer = () => {
  const { treeData, openDirs, renameFile } = useSandpackFiles();
  const { readOnly } = useContext(SandpackUIContext);
  const { sandpack } = useSandpack();
  const { trackEvent } = useTracking();

  const handleDrop = (
    newTreeData: SimpleNodeModel[],
    node: DropOptions<{ path: string }>,
  ) => {
    const newItem = {
      parent: node.dropTargetId as string,
      text: (node?.dragSource?.text || node.dragSourceId) as string,
    };

    const oldPath = node?.dragSource?.data?.path as string;
    const path = `/${buildPath(newItem, newTreeData as Item[])}`;

    const isSourceRestricted = RESTRICTED_FILES.includes(oldPath);
    const fileExistsAtTarget = Boolean(sandpack.files[path]);
    const targetIsSource = node.dragSource?.parent === node.dropTargetId;

    if (isSourceRestricted || targetIsSource || fileExistsAtTarget) {
      return;
    }

    const isDir = node?.dragSource?.droppable;

    const newPath = isDir ? `${path}/` : path;
    const formattedOldPath = isDir ? `${oldPath}/` : oldPath;
    const type = isDir ? "directory" : "file";

    trackEvent(`playground.move-${type}`, {
      prevPath: formattedOldPath,
      path: newPath,
    });

    renameFile(formattedOldPath, newPath);
  };

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className="file-tree-explorer">
        <FileTreeExplorerActions activeFile={sandpack.activeFile} />
        <Tree
          tree={treeData}
          rootId="/"
          onDrop={handleDrop}
          enableAnimateExpand
          canDrag={() => {
            return !readOnly;
          }}
          sort
          classes={{
            dropTarget: "file-tree-explorer__drop-target",
          }}
          initialOpen={openDirs}
          render={(node, { isOpen, depth }) => {
            if (!node.text) return <></>;

            if (node.text.includes("addFile") || node.text.includes("addDir")) {
              return (
                <AddFileForm node={node} files={sandpack.files} depth={depth} />
              );
            }

            if (node.droppable) {
              return (
                <OpenFolderButton
                  files={sandpack.files}
                  isOpen={isOpen}
                  node={node}
                  depth={depth}
                />
              );
            }
            return (
              <OpenFileButton
                depth={depth}
                files={sandpack.files}
                activeFile={sandpack.activeFile}
                node={node}
                openFile={sandpack.openFile}
              />
            );
          }}
        />
      </div>
    </DndProvider>
  );
};
