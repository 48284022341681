import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AnimaCodeEditor } from "./components/AnimaCodeEditor/AnimaCodeEditor";
import { DevtoolsProvider } from "./components/SandpackDevTools/SandpackDevTools";
import { RootLayout } from "./components/RootLayout/RootLayout";
import { GenerationSessionProvider } from "./providers/GenerationSession";
import "toastify-js/src/toastify.css";
import "balloon-css";
import { SandpackUIProvider } from "./providers/SandpackUIContext";
import { ModalProvider } from "./providers/ModalContext";
import { SaveChangesProvider } from "./providers/SaveChanges";
import { DownloadProjectProvider } from "./providers/DownloadProject";
import { ToastProvider } from "./providers/Toast/Toast";

const router = createBrowserRouter([
  {
    path: "/:id",
    element: (
      <RootLayout>
        <AnimaCodeEditor />
      </RootLayout>
    ),
  },
  {
    path: "/",
    element: (
      <RootLayout>
        <h1 data-testid="root-error-msg">We are unable to access your code</h1>
        <p>Please reopen the link you were given from the Anima Plugin</p>
      </RootLayout>
    ),
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GenerationSessionProvider>
        <DevtoolsProvider>
          <SaveChangesProvider>
            <DownloadProjectProvider>
              <SandpackUIProvider>
                <ToastProvider>
                  <ModalProvider>
                    <RouterProvider router={router} />
                  </ModalProvider>
                </ToastProvider>
              </SandpackUIProvider>
            </DownloadProjectProvider>
          </SaveChangesProvider>
        </DevtoolsProvider>
      </GenerationSessionProvider>
    </QueryClientProvider>
  );
}

export default App;
