import { PropsWithChildren, useContext, useState } from "react";
import { RenameFileForm } from "../RenameFileForm/RenameFileForm";
import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { RESTRICTED_FILES } from "../../constants/constants";
import { FileContextMenu } from "../FileContextMenu/FileContextMenu";
import { ModalContext } from "../../providers/ModalContext";
import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import { SimpleNodeModel } from "../../utilities/getNodePath";
import { useTracking } from "../../tracking";
import "./OpenButtonBase.css";
import { SandpackUIContext } from "../../providers/SandpackUIContext";

interface Props extends PropsWithChildren {
  node: SimpleNodeModel;
  files: SandpackBundlerFiles;
  isDir?: boolean;
  handleClick: () => void;
  label: string;
  depth: number;
  isActive: boolean;
}

export function OpenButtonBase(props: Props) {
  const {
    node,
    files,
    isDir = false,
    children,
    handleClick,
    label,
    depth,
    isActive,
  } = props;
  const [isRenaming, setIsRenaming] = useState(false);
  const { showModal } = useContext(ModalContext);
  const { readOnly } = useContext(SandpackUIContext);
  const { deleteFile } = useSandpackFiles();
  const nodePath = node?.data?.path as string;
  const { trackEvent } = useTracking();
  const nodeType = isDir ? "directory" : "file";
  const paddingLeft = `${depth * 8 + 16}px`;

  if (isRenaming) {
    return (
      <div className="open-button-base" style={{ paddingLeft }}>
        <RenameFileForm
          files={files}
          node={node}
          isDir={isDir}
          onCloseRename={() => setIsRenaming(false)}
        />
      </div>
    );
  }

  return (
    <div
      className={`open-button-base ${
        isActive ? "open-button-base--active" : ""
      }`}
      style={{ paddingLeft }}
    >
      <FileContextMenu
        nodePath={nodePath}
        isDir={isDir}
        onRenameStart={() => {
          trackEvent(`playground.rename-${nodeType}.start`, {
            prevPath: nodePath,
          });
          return setIsRenaming(true);
        }}
      >
        <button
          className="open-button"
          aria-label={label}
          key={node.id}
          data-variant="link"
          onKeyDown={(e) => {
            if (readOnly) return;

            if (e.key === "Enter") {
              if (!RESTRICTED_FILES.includes(nodePath)) {
                e.preventDefault();
                trackEvent(`playground.rename-${nodeType}.start`, {
                  prevPath: nodePath,
                });
                setIsRenaming(true);
              }
            }

            if (e.key === "Backspace" && e.metaKey) {
              if (!RESTRICTED_FILES.includes(nodePath)) {
                e.preventDefault();
                trackEvent(`playground.delete-${nodeType}.start`, {
                  path: nodePath,
                });

                showModal({
                  id: "delete-file-modal",
                  props: {
                    isDir,
                    actionName: `delete-${nodeType}`,
                    fileName: nodePath,
                    handleConfirmDelete: () => {
                      const deletePath = isDir ? `${nodePath}/` : nodePath;
                      return deleteFile(deletePath);
                    },
                  },
                });
              }
            }
          }}
          onClick={handleClick}
        >
          {children}
        </button>
      </FileContextMenu>
    </div>
  );
}
