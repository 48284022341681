import { useSandpack } from "@codesandbox/sandpack-react";
import { useEffect, useRef } from "react";
import { useTracking } from "../tracking";

export const useTrackActiveFile = () => {
  const { sandpack } = useSandpack();
  const { trackEvent } = useTracking();
  const isInitialFile = useRef(true);

  useEffect(() => {
    // we don't want to track the initial file change
    if (!isInitialFile.current) {
      trackEvent("playground.file-change", {
        file: sandpack.activeFile,
      });
    }
    isInitialFile.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sandpack.activeFile]);

  return null;
};
