import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { Environment, endpoints } from "../config/endpoints";
import { mapCSBFilesToAnimaFiles } from "../utilities/mapAnimaFilesToCSBFiles";

interface Params {
  environment: Environment;
  sessionId: string;
  files: SandpackBundlerFiles;
}

export const saveChanges = async (params: Params) => {
  const csbFiles = params.files;
  const files = mapCSBFilesToAnimaFiles(csbFiles);

  const apiUrl = endpoints[params.environment];
  const res = await fetch(
    `${apiUrl}/generation_sessions/${params.sessionId}/sandpack`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        files,
      }),
    },
  );

  return res.json();
};
