import { FileNode } from "../FileNode/FileNode";
import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { OpenButtonBase } from "../OpenButtonBase/OpenButtonBase";
import { SimpleNodeModel } from "../../utilities/getNodePath";

interface Props {
  node: SimpleNodeModel;
  openFile: (path: string) => void;
  activeFile: string;
  depth: number;
  files: SandpackBundlerFiles;
}

export function OpenFileButton(props: Props) {
  const { node, openFile, activeFile, files, depth } = props;
  const nodePath = node?.data?.path as string;
  const isActive = activeFile === nodePath;

  return (
    <OpenButtonBase
      node={node}
      files={files}
      label={node.text}
      handleClick={() => {
        openFile(nodePath);
      }}
      depth={depth}
      isActive={isActive}
    >
      <FileNode node={node} isActive={isActive} />
    </OpenButtonBase>
  );
}
