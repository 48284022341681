import { useSandpack } from "@codesandbox/sandpack-react";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DownloadProjectContext } from "../providers/DownloadProject";
import type { SandpackBundlerFiles } from "@codesandbox/sandpack-client";

export const useAddDownloadListener = () => {
  const { sandpack } = useSandpack();
  const { files } = sandpack;
  const { id } = useParams<{ id: string }>();

  const { download } = useContext(DownloadProjectContext);

  useEffect(() => {
    const handleDownload = () => {
      if (!id) return;
      return download({
        files: adjustFilesForPackageDownload(files),
        sessionId: id,
      });
    };

    document.addEventListener("playground:download", handleDownload);

    return () => {
      document.removeEventListener("playground:download", handleDownload);
    };
  }, [files, id, download]);

  return null;
};

function adjustFilesForPackageDownload(sandpackFiles: SandpackBundlerFiles) {
  const files = adjustTailwindFilesForPackageDownload(sandpackFiles);
  return files;
}

function adjustTailwindFilesForPackageDownload(
  sandpackFiles: SandpackBundlerFiles,
) {
  const tailwindConfig = sandpackFiles["/tailwind.config.js"];
  if (!tailwindConfig) return sandpackFiles;
  const files = JSON.parse(JSON.stringify(sandpackFiles));
  files["/tailwind.config.js"].code = sandpackFiles[
    "/tailwind.config.js"
  ].code.replace("tailwind.config = {", "module.exports = {");
  Object.keys(files).forEach((key) => {
    const script = `<script type="module" src="./tailwind.config.js"></script>`;
    const code = files[key].code;
    if (code.includes(script)) {
      files[key].code = code.replace(script, "");
    }
    if (code.includes(`"../tailwind.config.js";`)) {
      files[key].code = code.replace(
        'import "../tailwind.css";\nimport "../tailwind.config.js";\n',
        "",
      );
    }
  });
  return files;
}
