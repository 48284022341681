import { SandpackFilesProvider } from "../../providers/SandpackFilesProvider";
import { FileTreeExplorer } from "../FileTreeExplorer/FileTreeExplorer";
import "./SandpackFileExplorer.css";

export const SandpackFileExplorer = () => {
  return (
    <SandpackFilesProvider>
      <div className="sandpack-file-explorer">
        <FileTreeExplorer />
      </div>
    </SandpackFilesProvider>
  );
};
