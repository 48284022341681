import {
  SandpackFiles,
  SandpackPredefinedTemplate,
} from "@codesandbox/sandpack-react";
import { getFileContents } from "./getFileContents";
import { attachGlobalCssToEntryFile } from "./moveInlineCssToSeparateFile";
import { importTailwindFiles } from "./importTailwindFiles";
import { isInPackageJson, isReactTypescript } from "./fileHelpers";

const updateMainEntry = (files: SandpackFiles, entry: string) => {
  const packageJson = JSON.parse(getFileContents(files["package.json"]));
  packageJson.main = entry;
  files["package.json"] = JSON.stringify(packageJson, null, 2);
};

const defaultOptions = {
  classes: {
    "sp-wrapper": "codesandbox-wrapper",
  },
};

const reactOptions = {
  ...defaultOptions,
  activeFile: "/src/index.jsx",
};

const reactTsOptions = {
  ...defaultOptions,
  activeFile: "/src/index.tsx",
};

const defaultTemplate = {
  customSetup: {},
  options: defaultOptions,
};

const staticTemplate = {
  customSetup: {
    entry: "/index.html",
    environment: "static",
  },
  options: defaultOptions,
} as const;

const tailwindTemplate = {
  customSetup: {},
  options: {
    ...reactOptions,
    externalResources: ["https://cdn.tailwindcss.com"],
  },
};

const tailwindTsTemplate = {
  customSetup: {},
  options: {
    ...reactTsOptions,
    externalResources: ["https://cdn.tailwindcss.com"],
  },
};

const vueTemplate = {
  customSetup: {},
  options: {
    ...defaultOptions,
    activeFile: "/src/main.js",
  },
};

const getTemplate = (key: string) => {
  if (key === "react") {
    return {
      ...defaultTemplate,
      options: reactOptions,
    };
  }

  if (key === "react-ts") {
    return {
      ...defaultTemplate,
      options: reactTsOptions,
    };
  }

  if (key === "react-tailwind") {
    return tailwindTemplate;
  }

  if (key === "react-ts-tailwind") {
    return tailwindTsTemplate;
  }

  if (key === "static") {
    return staticTemplate;
  }

  if (key === "vue") {
    return vueTemplate;
  }

  return defaultTemplate;
};

export const deriveConfigFromFiles = (
  files: SandpackFiles,
): {
  customSetup?: Record<string, unknown>;
  template?: SandpackPredefinedTemplate;
  options: Record<string, unknown>;
} => {
  const packageJson = getFileContents(files["package.json"] ?? "");

  if (isInPackageJson(packageJson, "react")) {
    const isTailwind = files["tailwind.css"] !== undefined;

    attachGlobalCssToEntryFile(files);

    if (isTailwind) {
      importTailwindFiles(files);

      if (isReactTypescript(files)) {
        updateMainEntry(files, "src/index.tsx");
        return getTemplate("react-ts-tailwind");
      }

      updateMainEntry(files, "src/index.jsx");
      return getTemplate("react-tailwind");
    }

    if (isReactTypescript(files)) {
      updateMainEntry(files, "src/index.tsx");
      return getTemplate("react-ts");
    }

    updateMainEntry(files, "src/index.jsx");
    return getTemplate("react");
  }

  if (isInPackageJson(packageJson, "vue")) {
    attachGlobalCssToEntryFile(files);
    return getTemplate("vue");
  }

  return getTemplate("static");
};
