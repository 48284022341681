import * as Sentry from "@sentry/react";
import type { BrowserOptions } from "@sentry/browser";
import { version } from "../../package.json";

const isProd = import.meta.env.PROD;
const sentryEnv = import.meta.env.MODE;

const sentryDSN =
  "https://6b07dd5d5c0ba105ffb90de11ac127c9@o40032.ingest.sentry.io/4506535161233408";
const sentryRelease = isProd ? JSON.stringify(version) : "dev";

const tracesSampleRate = isProd ? 0.25 : 1;
const replaysSessionSampleRate = isProd ? 0.05 : 0;
const replaysOnErrorSampleRate = isProd ? 0.4 : 0;

const sentryConfig: BrowserOptions = {
  dsn: sentryDSN,
  release: sentryRelease,
  environment: sentryEnv,
  tracesSampleRate,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
};
Sentry.init(sentryConfig);
