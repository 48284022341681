import { buildPath } from "./buildPath";
import { Item } from "./toHierarchicalArray";

export const getAllChildrenOfDir = (dir: string, arr: Item[]) => {
  const children: string[] = [];
  const childrenOfDir = arr?.filter(({ parent }) => parent === dir);

  childrenOfDir.forEach((child) => {
    children.push(buildPath(child, arr));
    if (child.id !== "/" && child.id !== dir) {
      // Only call the function recursively if the child has a parent
      children.push(...getAllChildrenOfDir(child.id, arr));
    }
  });
  return children;
};
