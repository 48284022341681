import { SandpackClient } from "@codesandbox/sandpack-client";
import { useEffect, useState } from "react";

export const usePreviewNavigation = (client: SandpackClient | null) => {
  const [currentRoute, setCurrentRoute] = useState<string>("");
  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (client) {
      const iframeElement = client.iframe;
      setIframe(iframeElement);
    }
  }, [client]);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type !== "screenChange") {
        return;
      }
      setCurrentRoute(`/${event.data.data.screenSlug}`);
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (iframe && currentRoute) {
      const url = new URL(iframe.src);
      url.pathname = currentRoute;
      iframe.src = url.toString();
    }
  }, [currentRoute, iframe]);

  return { currentRoute };
};
