import { FormEventHandler, useRef, useState } from "react";
import type { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import "./SingleInputForm.css";

interface Props {
  isDirectory: boolean;
  onBlur: () => void;
  files: SandpackBundlerFiles;
  currentPath?: string;
  onSubmit: (value: string) => void;
  defaultValue?: string;
}

export const SingleInputForm = (props: Props) => {
  const {
    isDirectory,
    files,
    onBlur,
    currentPath = "/",
    onSubmit,
    defaultValue,
  } = props;
  const [hasError, setHasError] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      return setHasError(false);
    }

    if (files[`${currentPath}/${value}`] || files[`${currentPath}/${value}/`]) {
      return setHasError(true);
    }

    return setHasError(false);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      return onBlur();
    }

    handleSubmit();
  };

  const _handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    const form = formRef.current!;
    const formValue = new FormData(form);
    const name = formValue.get("name") as string;

    let nameOverride = name.trim();

    if (isDirectory && !name.endsWith("/")) {
      nameOverride = `${name}/`;
    } else if (name.endsWith("/")) {
      nameOverride = name.substring(0, -1);
    }

    onSubmit(nameOverride);

    form.reset();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
    if (e.key === "Escape") {
      e.preventDefault();
      onBlur();
    }
  };

  return (
    <form ref={formRef} onSubmit={_handleSubmit}>
      <input
        type="text"
        className={`single-input-form__input ${
          hasError ? "single-input-form__input--error" : ""
        }`}
        onBlur={handleBlur}
        autoFocus
        defaultValue={defaultValue}
        placeholder={isDirectory ? "Directory name" : "File name"}
        name="name"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {hasError ? (
        <p className="single-input-form__error">
          File or Directory already exists
        </p>
      ) : null}
    </form>
  );
};
