import { Item } from "./toHierarchicalArray";

export const buildPath = (
  item: { parent: string; text: string },
  arr: Item[],
) => {
  let path = "";

  const parent = arr.find((i) => i.id === item.parent);

  if (parent) {
    path = `${buildPath(parent, arr)}/${path}`;
  }

  path += `${item.text}`;

  return path;
};
