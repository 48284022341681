// Based on https://github.com/AaronPowell96/sandpack-file-explorer/blob/main/src/SandpackFilesProvider.tsx

import { SandpackFiles, useSandpack } from "@codesandbox/sandpack-react";
import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { Item } from "../utilities/toHierarchicalArray";
import { getAllParentDirs } from "../utilities/getAllParentDirs";
import { getAllChildrenOfDir } from "../utilities/getAllChildrenOfDir";
import { deleteKeys } from "../utilities/deleteKeys";
import { getDirPath } from "../utilities/getNodePath";
import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { createTreeFromFiles } from "../utilities/createTreeFromFiles";
import { RESTRICTED_FILES } from "../constants/constants";
import { SaveChangesContext } from "./SaveChanges";

export const SandpackFilesContext = createContext<{
  treeData: Item[];
  setTreeData: Dispatch<React.SetStateAction<Item[]>>;
  openDirs: string[];
  setOpenDirs: Dispatch<React.SetStateAction<string[]>>;
  addFile: (
    pathOrFiles: Record<string, string> | SandpackFiles,
    code?: string,
  ) => void;
  deleteFile: (file: string) => void;
  renameFile: (oldPath: string, newPath: string) => void;
}>({
  treeData: [],
  setTreeData: () => [],
  openDirs: [],
  setOpenDirs: () => null,
  addFile: () => Promise.resolve(),
  deleteFile: () => Promise.resolve(),
  renameFile: () => Promise.resolve(),
});

export const SandpackFilesProvider = ({ children }: PropsWithChildren) => {
  const { sandpack } = useSandpack();
  const fileStructure = createTreeFromFiles({ ...sandpack.files });
  const activeFilekey = sandpack.activeFile.substring(1);
  const initialOpenDirs = getAllParentDirs({
    childId: activeFilekey,
    tree: fileStructure,
  });

  const [treeData, setTreeData] = useState<Item[]>(fileStructure);
  const [openDirs, setOpenDirs] = useState<string[]>(initialOpenDirs);
  const { onFileChange } = useContext(SaveChangesContext);

  const deleteFile = (file: string) => {
    return deleteFiles([file]);
  };

  const deleteFiles = (files: string[]) => {
    const entryFile = JSON.parse(sandpack.files["/package.json"]?.code)?.main;

    if (files.includes(entryFile)) {
      return;
    }

    const sandpackFilesCopy = { ...sandpack.files };

    files.forEach((key) => {
      const isDir = key.endsWith("/");
      if (isDir) {
        const dirId = key.substring(0, key.length - 1);
        const parentId = dirId.substring(1);
        sandpack.deleteFile(dirId);
        delete sandpackFilesCopy[dirId];

        const childrenOfDir = getAllChildrenOfDir(parentId, treeData);

        childrenOfDir.forEach((child) => {
          sandpack.deleteFile(`/${child}`);
          delete sandpackFilesCopy[`/${child}`];
        });
      }

      sandpack.deleteFile(key);
      delete sandpackFilesCopy[key];
    });

    const newFiles = deleteKeys(sandpackFilesCopy, files);

    onFileChange(Object.keys(newFiles));

    setTreeData(createTreeFromFiles(newFiles));
  };

  const addFile = (files: SandpackFiles) => {
    for (const id in files) {
      const fileExists = sandpack.files[id];
      const directoryExists = treeData.some((node) => node.id === `${id}/`);

      if (fileExists || directoryExists) {
        delete files[id];
      }
    }

    sandpack.addFile(files);

    const fileKeys = Object.keys(files);
    const isAnInputNode = fileKeys.some(
      (key) => key.includes("addFile") || key.includes("addDir"),
    );

    const key = Object.keys(files)[0].substring(1);
    let newFiles = { ...sandpack.files };

    if (!isAnInputNode) {
      const tempAddNodes = Object.keys(sandpack.files).filter((key) => {
        return key.includes("addFile") || key.includes("addDir");
      });

      deleteFiles(tempAddNodes);
      newFiles = deleteKeys({ ...sandpack.files }, tempAddNodes);
    }

    const newTree = createTreeFromFiles(newFiles, files);
    onFileChange(Object.keys(fileKeys));
    setTreeData(newTree);

    const directoryIds = getAllParentDirs({ childId: key, tree: newTree });

    setOpenDirs((prev) => {
      return Array.from(new Set([...prev, ...directoryIds]));
    });

    if (!isAnInputNode) {
      fileKeys.forEach((id) => {
        if (!id.endsWith("/")) {
          sandpack.openFile(id);
        }
      });
    }
  };

  const renameDir = (oldKey: string, newKey: string) => {
    const oldDirPath = getDirPath(oldKey);
    const _children = getAllChildrenOfDir(oldDirPath.substring(1), treeData);
    const children = _children.map((child) => `/${child}`);

    const newFiles: SandpackBundlerFiles = {};

    children.forEach((child) => {
      if (!sandpack.files[child]) return;
      const updatedKey = child.replace(
        oldKey.substring(1),
        newKey.substring(1),
      );
      newFiles[updatedKey] = sandpack.files[child];
    });

    deleteFiles([oldKey]);

    const updatedKeys = deleteKeys(sandpack.files, children);
    const updatedFiles = { ...updatedKeys, ...newFiles };
    const newTree = createTreeFromFiles(updatedFiles, {
      [newKey]: {
        code: ".emptyDir",
        hidden: true,
      },
    });

    setTreeData(newTree);
    const parentDirs = getAllParentDirs({
      childId: getDirPath(newKey.substring(1)),
      tree: newTree,
    });

    setOpenDirs((prev) => {
      return Array.from(new Set([...prev, ...parentDirs]));
    });

    sandpack.addFile(newFiles);
  };

  const _renameFile = (oldFileKey: string, newFileKey: string) => {
    const existingFile = sandpack.files[oldFileKey];
    const copyOfFile = { ...existingFile };

    const newFile = { [newFileKey]: copyOfFile };

    deleteFile(oldFileKey);

    const newFiles = { ...sandpack.files, ...newFile };
    const updatedFiles = deleteKeys(newFiles, [oldFileKey]);
    const newTree = createTreeFromFiles(updatedFiles);
    setTreeData(newTree);
    const directoryIds = getAllParentDirs({
      childId: newFileKey.substring(1),
      tree: newTree,
    });

    setOpenDirs((prev) => {
      return Array.from(new Set([...prev, ...directoryIds]));
    });

    sandpack.addFile(newFile);
    sandpack.openFile(newFileKey);
  };

  const renameFile = (oldFileKey: string, newFileKey: string) => {
    const fileExists = sandpack.files[newFileKey];
    const directoryExists = treeData.some((node) => node.id === newFileKey);

    if (fileExists || directoryExists) {
      return;
    }

    if (RESTRICTED_FILES.includes(oldFileKey)) {
      return;
    }

    onFileChange(newFileKey);

    const isDir = oldFileKey.endsWith("/");
    if (isDir) {
      return renameDir(oldFileKey, newFileKey);
    }

    return _renameFile(oldFileKey, newFileKey);
  };

  const value = {
    treeData,
    setTreeData,
    openDirs,
    setOpenDirs,
    addFile,
    deleteFile,
    renameFile,
  };

  return (
    <SandpackFilesContext.Provider value={value}>
      {children}
    </SandpackFilesContext.Provider>
  );
};
