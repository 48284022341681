import { Item } from "./toHierarchicalArray";

export const getAllParentDirs = ({
  childId,
  tree,
}: {
  childId: string;
  tree: Item[];
}) => {
  const ids: string[] = [];
  const itemParent = tree.find(({ id }) => id === childId)?.parent;

  if (!itemParent || itemParent === "/") {
    return ids;
  }
  ids.push(`${itemParent}`);
  ids.push(...getAllParentDirs({ childId: itemParent, tree }));
  return ids;
};
