import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import { SingleInputForm } from "../SingleFormInput/SingleInputForm";
import { SimpleNodeModel, getNodePath } from "../../utilities/getNodePath";
import type { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { useTracking } from "../../tracking";

interface Props {
  node: SimpleNodeModel;
  files: SandpackBundlerFiles;
  depth: number;
}

export function AddFileForm(props: Props) {
  const { node, files, depth } = props;
  const { deleteFile, addFile } = useSandpackFiles();
  const { trackEvent } = useTracking();
  const isDirectory = node.text.includes("addDir");
  const nodeType = isDirectory ? "directory" : "file";

  const paddingLeft = `${depth * 8 + 16}px`;

  return (
    <div style={{ paddingLeft }}>
      <SingleInputForm
        files={files}
        onBlur={() => {
          trackEvent(`playground.create-${nodeType}.cancel`);
          deleteFile(`${node?.data?.path}`);
        }}
        isDirectory={isDirectory}
        currentPath={getNodePath(node)}
        onSubmit={(fileName: string) => {
          const newPath = getNodePath(node);
          const path = `${newPath}/${fileName}`;
          trackEvent(`playground.create-${nodeType}.confirm`, {
            path,
          });

          addFile({
            [path]: {
              code: node.text.includes("addDir")
                ? ".emptyDir"
                : `// ${fileName}`,
              hidden: node.text.includes("addDir"),
            },
          });
        }}
      />
    </div>
  );
}
