import * as Dialog from "@radix-ui/react-dialog";
import "./DialogBase.css";
import { useTracking } from "../../tracking";

interface Props {
  title: string;
  subtitle: string;
  actions: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  actionName?: string;
}

export const DialogBase = (props: Props) => {
  const { title, subtitle, actions, open, handleClose, actionName } = props;
  const { trackEvent } = useTracking();

  const trackClose = () => {
    if (!actionName) return;
    trackEvent(`playground.${actionName}.cancel`, {
      path: title,
    });
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        if (!e) handleClose();
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="dialog__overlay" />
        <Dialog.Content
          onEscapeKeyDown={trackClose}
          onPointerDownOutside={trackClose}
          className="dialog__content"
        >
          <Dialog.Title className="dialog__title">{title}</Dialog.Title>
          <Dialog.Description className="dialog__description">
            {subtitle}
          </Dialog.Description>
          <div className="dialog__actions">{actions}</div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
