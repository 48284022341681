import {
  SandpackProvider,
  LoadingOverlay,
  defaultDark,
} from "@codesandbox/sandpack-react";

export function LoadingPage() {
  return (
    <SandpackProvider
      files={{
        ["/index.html"]: "",
      }}
      theme={defaultDark}
      customSetup={{
        entry: "index.html",
      }}
    >
      <LoadingOverlay showOpenInCodeSandbox={false} />
    </SandpackProvider>
  );
}
