import { SandpackFiles } from "@codesandbox/sandpack-react";
import type { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { arrayToObject } from "./arrayToObject";
import { deepMerge } from "./deepMerge";

export type Files =
  | SandpackBundlerFiles
  | Record<string, string>
  | SandpackFiles;
export type OverrideFiles = Record<string, string> | string | SandpackFiles;

export const directoryFileMap = (
  files: Files,
  overrides: OverrideFiles = {},
) => {
  const _overrides =
    typeof overrides === "string" ? { [overrides]: "" } : overrides ?? {};

  const combinedFiles = { ...files, ..._overrides };

  const map = Object.keys(combinedFiles).reduce((acc, curr) => {
    const split = curr.split("/").splice(1);
    const dirTree = arrayToObject(split);
    acc = deepMerge(files as SandpackBundlerFiles, acc, dirTree, curr);

    return acc;
  }, {});

  return map;
};
