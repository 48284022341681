import { useSandpack } from "@codesandbox/sandpack-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// Ideally, Sandpack would allow us to automatically sync to the URL, but it's not OOB yet: https://github.com/codesandbox/sandpack/issues/1057
export const useSyncActiveFileToUrl = () => {
  const [, setSearchParams] = useSearchParams();
  const { sandpack } = useSandpack();
  const activeFile = sandpack.activeFile;

  useEffect(() => {
    setSearchParams((params) => {
      return {
        ...Object.fromEntries(params.entries()),
        file: activeFile,
      };
    });
  }, [activeFile, setSearchParams]);

  return null;
};
