import {
  SandpackLayout,
  SandpackPreview,
  SandpackPreviewRef,
} from "@codesandbox/sandpack-react";
import { useContext, useRef } from "react";
import { useResizablePanels } from "../../utilities/useResizablePanels";
import "./Sandpack.css";
import { useSyncActiveFileToUrl } from "../../hooks/useSyncActiveFileToUrl";
import { SandpackPreviewActions } from "../SandpackPreviewActions/SandpackPreviewActions";
import { SandpackUIContext } from "../../providers/SandpackUIContext";
import { SandpackFileExplorer } from "../SandpackFileExplorer/SandpackFileExplorer";
import { useTrackCopy } from "../../hooks/useTrackCopy";
import { useTrackActiveFile } from "../../hooks/useTrackActiveFile";
import { useTrackBuildError } from "../../hooks/useTrackBuildError";
import { CodeEditor } from "../CodeEditor/CodeEditor";
import { useAddSaveListener } from "../../hooks/useAddSaveListener";
import { useAddDownloadListener } from "../../hooks/useAddDownloadListener";
import { usePreviewNavigation } from "../../hooks/usePreviewNavigation";
import { useSearchParams } from "react-router-dom";

export function Sandpack() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dragEventTargetRef = useRef<any>(null);
  const previewRef = useRef<SandpackPreviewRef | null>(null);
  const values = useContext(SandpackUIContext);
  const [searchParams] = useSearchParams();

  useSyncActiveFileToUrl();
  useTrackCopy();
  useTrackActiveFile();
  useTrackBuildError();

  useAddSaveListener();
  useAddDownloadListener();
  usePreviewNavigation(previewRef?.current?.getClient() || null);

  const { horizontalSize } = useResizablePanels({
    dragEventTargetRef,
  });

  const selectedScreen = searchParams.get("screen");
  const startRoute = selectedScreen ? `/${selectedScreen}` : "/";

  return (
    <div className="layout-wrapper full-height">
      {values.showFileExplorer && <SandpackFileExplorer />}
      <SandpackLayout>
        {values.showCodeEditor && (
          <CodeEditor
            style={{
              flexGrow: horizontalSize,
              flexShrink: horizontalSize,
              ...(values.readOnly
                ? { pointerEvents: "none", userSelect: "none" }
                : {}),
            }}
          />
        )}

        {values.resizablePanels && (
          <div
            className="resize-handler"
            onMouseDown={(event): void => {
              dragEventTargetRef.current = event.target;
            }}
            style={{
              left: `calc(${horizontalSize}% - 12px)`,
            }}
          />
        )}
        <SandpackPreview
          style={{
            flexGrow: 100 - horizontalSize,
            flexShrink: 100 - horizontalSize,
            width: 100 - horizontalSize + "%",
          }}
          actionsChildren={<SandpackPreviewActions />}
          showRefreshButton={false}
          showNavigator={values.showNavigator}
          showOpenInCodeSandbox={false}
          startRoute={startRoute}
          ref={previewRef}
        />
      </SandpackLayout>
    </div>
  );
}
