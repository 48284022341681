import { SandpackFiles, SandpackFile } from "@codesandbox/sandpack-react";
import { getFileContents } from "./getFileContents";
import { insertIntoHead } from "./insertIntoHead";
import { getDirPath } from "./getNodePath";
import { getEntryFile } from "./fileHelpers";

const headContentRegex = /<head[^>]*>([\s\S]*?)<\/head>/i;
const styleContentRegex = /<style[^>]*>([\s\S]*?)<\/style>/gi;

// A (hopefully) temporary workaround until this is resolved: https://github.com/codesandbox/sandpack/issues/1049
export const attachGlobalCssToEntryFile = (files: SandpackFiles) => {
  const pathToIndexHtml = findPathToIndexHtml(files);

  if (!pathToIndexHtml) {
    console.error("Could not find index.html file in files object.");
    return;
  }

  updateCss(files, pathToIndexHtml);
};

const updateCss = (files: SandpackFiles, pathToIndexHtml: string) => {
  const pathToIndexDir = getDirPath(pathToIndexHtml);
  const inlineCss = generateFilesForInlineCss(files[pathToIndexHtml]);
  const filesToAdd: SandpackFiles = {};

  if (inlineCss["global.css"]) {
    const cssPath = pathToIndexDir
      ? `${pathToIndexDir}/global.css`
      : "global.css";
    filesToAdd[cssPath] = inlineCss["global.css"];
    files[cssPath] = inlineCss["global.css"];
  }

  const pathToStyleguide = pathToIndexDir
    ? `${pathToIndexDir}/styleguide.css`
    : "styleguide.css";
  if (files[pathToStyleguide]) {
    filesToAdd[pathToStyleguide] = files[pathToStyleguide];
  }

  if (!Object.keys(filesToAdd).length) {
    return;
  }

  const entryFileKey = getEntryFile(files);

  if (entryFileKey !== "index.html") {
    const entryFile = getFileContents(files[entryFileKey]);

    const imports = Object.keys(filesToAdd).map((key) => {
      return `import "../${key}";\n`;
    });

    const updatedEntryFile = `${imports.join("")}${entryFile}`;
    files[entryFileKey] = updatedEntryFile;
    return;
  }

  const { modifiedIndexHtml } = inlineCss;
  const indexHtmlFile = getFileContents(modifiedIndexHtml);
  const updatedContent = insertIntoHead(
    indexHtmlFile,
    `\n\t<link rel="stylesheet" href="./global.css">`,
  );
  files[pathToIndexHtml] = updatedContent;

  return "/index.html";
};

const findPathToIndexHtml = (files: SandpackFiles): string | undefined => {
  const fileKeys = Object.keys(files);
  const indexHtmlMatch = fileKeys.find((key) => key.endsWith("index.html"));
  return indexHtmlMatch;
};

const generateFilesForInlineCss = (
  file: SandpackFile | string,
): {
  "global.css"?: string;
  modifiedIndexHtml: string;
} => {
  const content = getFileContents(file);
  const match = content.match(headContentRegex);

  if (!match) {
    return {
      modifiedIndexHtml: content,
    };
  }

  let cssMatch;
  const styleElements = [];
  let modifiedContent = content;

  while ((cssMatch = styleContentRegex.exec(content)) !== null) {
    styleElements.push(cssMatch[1].trim());
    modifiedContent = modifiedContent.replace(cssMatch[0], "");
  }

  return {
    "global.css": styleElements.join("\n"),
    modifiedIndexHtml: modifiedContent,
  };
};
