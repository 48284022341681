import addFileSrc from "../../icons/add-file.svg";
import addDirSrc from "../../icons/add-dir.svg";
import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import { useTracking } from "../../tracking";

interface Props {
  dir?: boolean;
  activeFile: string;
}

export function CreateNewNode(props: Props) {
  const { dir = false, activeFile } = props;
  const { addFile } = useSandpackFiles();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    const activeSpace = activeFile.split("/").slice(0, -1).join("/");
    const nodeType = dir ? "directory" : "file";
    trackEvent(`playground.create-${nodeType}.start`);

    addFile({
      [`${activeSpace}/${dir ? "addDir" : "addFile"}`]: {
        code: ".emptyDir",
        hidden: true,
      },
    });
  };

  const imgSrc = dir ? addDirSrc : addFileSrc;

  return (
    <button
      aria-label={`${dir ? "Create Directory" : "Create File"}`}
      data-balloon-pos={`${dir ? "down-right" : "down"}`}
      data-variant="link"
      data-size="small"
      onClick={handleClick}
    >
      <img src={imgSrc} alt="" />
    </button>
  );
}
