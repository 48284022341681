import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import { FolderNode } from "../FolderNode/FolderNode";
import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { OpenButtonBase } from "../OpenButtonBase/OpenButtonBase";
import { SimpleNodeModel } from "../../utilities/getNodePath";

interface Props {
  node: SimpleNodeModel;
  isOpen: boolean;
  files: SandpackBundlerFiles;
  depth: number;
}

export function OpenFolderButton(props: Props) {
  const { node, isOpen, files, depth } = props;
  const { openDirs, setOpenDirs } = useSandpackFiles();
  const label = `${node.id}`;

  const handleClick = () => {
    if (isOpen) {
      const updatedDirs = openDirs.filter((dir) => dir !== label);
      setOpenDirs(updatedDirs);
    } else {
      setOpenDirs([...openDirs, label]);
    }
  };

  return (
    <OpenButtonBase
      handleClick={handleClick}
      files={files}
      node={node}
      label={label}
      isDir
      depth={depth}
      isActive={false}
    >
      <FolderNode isOpen={isOpen} node={node} />
    </OpenButtonBase>
  );
}
