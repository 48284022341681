import "./TopBar.css";
import copyImg from "../../icons/copy.svg";
import logoImg from "../../icons/logo.svg";
import Toastify from "toastify-js";
import { getIsEmbedded } from "../../utilities/getIsEmbedded";
import { useTracking } from "../../tracking";
import { SaveButton } from "../SaveButton/SaveButton";
import { DownloadButton } from "../DownloadButton/DownloadButton";
import { FeedbackButton } from "../FeedbackButton/FeedbackButton";
import { UpgradeButton } from "../UpgradeButton/UpgradeButton";
import { DownloadProjectContext } from "../../providers/DownloadProject";
import { useContext } from "react";

export function TopBar() {
  const isEmbedded = getIsEmbedded();
  const { trackEvent } = useTracking();
  const { isAllowed } = useContext(DownloadProjectContext);

  if (isEmbedded) return null;

  return (
    <header className="topbar">
      <div className="topbar__left">
        <img src={logoImg} alt="" />
      </div>
      {!isAllowed && <UpgradeButton />}
      <FeedbackButton />
      <SaveButton />
      <button
        data-size="small"
        data-variant="link"
        aria-label="Copy URL"
        data-balloon-pos="down"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          trackEvent("playground.copy-url", {
            url: window.location.href,
          });
          Toastify({
            text: "Link copied to clipboard",
            duration: 5000,
            close: true,
            gravity: "bottom",
            position: "right",
          }).showToast();
        }}
      >
        <img src={copyImg} alt="" />
      </button>
      <DownloadButton />
    </header>
  );
}
