import { SandpackDevTools } from "../SandpackDevTools/SandpackDevTools";
import { TopBar } from "../TopBar/TopBar";
import "./RootLayout.css";

export function RootLayout(props: { children: React.ReactNode }) {
  return (
    <div className="full-height">
      <SandpackDevTools />
      <div className="full-height root-layout">
        <TopBar />
        {props.children}
      </div>
    </div>
  );
}
