import { useContext } from "react";
import expandIcon from "../../icons/expand.svg";
import collapseIcon from "../../icons/collapse.svg";
import { SandpackUIContext } from "../../providers/SandpackUIContext";
import { useTracking } from "../../tracking";
import "./SandpackPreviewActions.css";

export function SandpackPreviewActions() {
  const { trackEvent } = useTracking();
  const { getIsFullScreen, toggleFullScreen } = useContext(SandpackUIContext);

  const handleToggleFullScreen = () => {
    const nextFullScreenState = !getIsFullScreen();

    if (nextFullScreenState) {
      trackEvent("playground.fullscreen.expand");
    } else {
      trackEvent("playground.fullscreen.collapse");
    }

    return toggleFullScreen(nextFullScreenState);
  };

  return (
    <div className="sandpack-preview-actions">
      {getIsFullScreen() ? (
        <button
          onClick={() => handleToggleFullScreen()}
          aria-label="Exit Fullscreen Preview"
          data-balloon-pos="up-right"
        >
          <img src={collapseIcon} alt="collapse-preview" />
        </button>
      ) : (
        <button
          onClick={() => handleToggleFullScreen()}
          aria-label="Enter Fullscreen Preview"
          data-balloon-pos="up-right"
        >
          <img src={expandIcon} alt="expand-preview" />
        </button>
      )}
    </div>
  );
}
