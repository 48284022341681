import { useContext } from "react";
import { SandpackFilesContext } from "../providers/SandpackFilesProvider";

export const useSandpackFiles = () => {
  const context = useContext(SandpackFilesContext);

  if (!context) {
    throw new Error(
      "useSandpackFiles must be used within a SandpackFilesProvider",
    );
  }

  return context;
};
