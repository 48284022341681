import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { SandpackFiles } from "@codesandbox/sandpack-react";

export type AnimaFiles = Record<
  string,
  {
    content: string;
    isBinary: boolean;
  }
>;

export const mapAnimaFilesToCSBFiles = (
  files: AnimaFiles | undefined,
): SandpackFiles => {
  if (!files) return {};
  return Object.entries(files).reduce((acc, [key, value]) => {
    acc[key] = {
      code: value.content,
    };
    return acc;
  }, {} as SandpackFiles);
};

export const mapCSBFilesToAnimaFiles = (
  files: SandpackBundlerFiles,
): AnimaFiles => {
  if (!files) return {};

  return Object.entries(files).reduce((acc, [key, value]) => {
    const cleanedKey = key.substring(1);
    acc[cleanedKey] = {
      content: value.code,
      isBinary: false,
    };
    return acc;
  }, {} as AnimaFiles);
};

export const isAnimaFilesType = (
  files: AnimaFiles | SandpackBundlerFiles,
): files is AnimaFiles => {
  const packageJsonFile = files["package.json"];

  if (
    packageJsonFile &&
    (typeof packageJsonFile === "string" || "code" in packageJsonFile)
  )
    return false;

  const indexFile = files["index.html"];
  if (indexFile && (typeof indexFile === "string" || "code" in indexFile))
    return false;

  return true;
};

export const prepareFilesForPlayground = (
  files: AnimaFiles | SandpackBundlerFiles | undefined,
) => {
  if (!files) return {} as SandpackFiles;

  if (isAnimaFilesType(files)) {
    return mapAnimaFilesToCSBFiles(files);
  }

  return files;
};
