import { buildPath } from "./buildPath";

export type Item = {
  id: string;
  parent: string;
  data: {
    path: string;
  };
  text: string;
  droppable: boolean;
};

export const toHierarchicalArray = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any,
  parentId = "/",
  arr: Item[] = [],
) => {
  const keys = Object.keys(files);

  keys.forEach((key) => {
    const newItem = {
      id: "",
      parent: parentId,
      data: {
        path: "",
      },
      text: key,
      droppable: false,
    };
    const path = buildPath(newItem, arr);

    const item: Item = {
      id: path,
      parent: parentId,
      data: {
        path: `/${path}`,
      },
      text: key,
      droppable: false,
    };

    if (typeof files[key] === "object" && files[key] !== null) {
      item.droppable = true;
      arr.push(item);
      toHierarchicalArray(files[key], path, arr);
    } else {
      arr.push(item);
    }
  });

  return arr;
};
