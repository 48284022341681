import closedFolderSrc from "../../icons/closed-folder.svg";
import openFolderSrc from "../../icons/open-folder.svg";
import "./FolderNode.css";
import { SimpleNodeModel } from "../../utilities/getNodePath";

interface Props {
  node: SimpleNodeModel;
  isOpen: boolean;
}

export function FolderNode(props: Props) {
  const { node, isOpen } = props;

  const imgSrc = isOpen ? openFolderSrc : closedFolderSrc;

  return (
    <span className="folder-node">
      <img src={imgSrc} alt="" />
      <span className="truncate">{node.text}</span>
    </span>
  );
}
