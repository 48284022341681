import { SandpackBundlerFiles } from "@codesandbox/sandpack-client";
import { SingleInputForm } from "../SingleFormInput/SingleInputForm";
import { useSandpackFiles } from "../../hooks/useSandpackFiles";
import { SimpleNodeModel, getNodePath } from "../../utilities/getNodePath";
import { useTracking } from "../../tracking";

interface Props {
  node: SimpleNodeModel;
  files: SandpackBundlerFiles;
  onCloseRename: () => void;
  isDir?: boolean;
}

export function RenameFileForm(props: Props) {
  const { node, files, onCloseRename, isDir = false } = props;
  const { renameFile } = useSandpackFiles();
  const { trackEvent } = useTracking();
  const nodeType = isDir ? "directory" : "file";

  return (
    <SingleInputForm
      files={files}
      onBlur={() => {
        trackEvent(`playground.rename-${nodeType}.cancel`);
        onCloseRename();
      }}
      defaultValue={node.text}
      isDirectory={isDir}
      currentPath={getNodePath(node)}
      onSubmit={async (fileName: string) => {
        const newPath = getNodePath(node);
        const path = `${newPath}/${fileName}`;

        if (
          fileName === node.text ||
          (isDir && fileName.slice(0, -1) === node.text)
        ) {
          trackEvent(`playground.rename-${nodeType}.cancel`);
          onCloseRename();
          return;
        }

        if (isDir) {
          renameFile(`${node?.data?.path}/`, path);
        } else {
          renameFile(`${node?.data?.path}`, path);
        }

        trackEvent(`playground.rename-${nodeType}.confirm`, {
          prevPath: `${node?.data?.path}`,
          path,
        });

        onCloseRename();
      }}
    />
  );
}
