import { autocompletion, completionKeymap } from "@codemirror/autocomplete";
import { SandpackCodeEditor, useSandpack } from "@codesandbox/sandpack-react";
import { ViewPlugin, ViewUpdate, KeyBinding } from "@codemirror/view";
import { useContext } from "react";
import { SandpackUIContext } from "../../providers/SandpackUIContext";
import { SaveChangesContext } from "../../providers/SaveChanges";

interface Config {
  onDocumentChanged: () => void;
}

const docChangedExtension = (config: Config) =>
  ViewPlugin.fromClass(
    class {
      onDocumentChanged: () => void;

      constructor() {
        this.onDocumentChanged = config.onDocumentChanged;
      }

      update(update: ViewUpdate) {
        if (update.docChanged) {
          this.onDocumentChanged();
        }
      }
    },
  );

export function CodeEditor(props: { style: React.CSSProperties }) {
  const values = useContext(SandpackUIContext);
  const { onFileChange } = useContext(SaveChangesContext);
  const { sandpack } = useSandpack();

  const activeFile = sandpack.activeFile;

  return (
    <SandpackCodeEditor
      style={props.style}
      showLineNumbers
      showInlineErrors
      extensions={[
        autocompletion(),
        docChangedExtension({
          onDocumentChanged: () => onFileChange(activeFile),
        }),
      ]}
      extensionsKeymap={completionKeymap as KeyBinding[]}
      readOnly={values.readOnly}
      showTabs={values.showTabs}
      closableTabs={values.closableTabs}
    />
  );
}
