import { NodeModel } from "@minoru/react-dnd-treeview";

export type SimpleNodeModel = NodeModel<{ path: string }>;

export const getNodePath = (node: SimpleNodeModel) => {
  return getDirPath(node?.data?.path ?? "");
};

export const getDirPath = (path: string) => {
  return path.split("/").slice(0, -1).join("/");
};
